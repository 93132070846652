.navbar {
background-color: white;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      color: black;
      text-align: center;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.image {
   height: 70px;
  
}
.links {
  margin-right: 50px;
  display: flex;
  align-items: center;
}  
ul{
  list-style-type: none;
  font-family: 'Quicksand', sans-serif;
  display: flex;
  margin-left: 750px;
  flex-direction: row ;
 
}
ul li{
    padding: 20px;
}

.links a {
  text-decoration: none;
  color: rgb(0, 0, 0);
  margin-left: 20px;
  font-size: 25px;
}

@import url('https://fonts.googleapis.com/css?family=Oswald|Sacramento&display=swap');
body{
    background-color: #fff;
}
.merlin-grids1,.merlin-grids2{
    padding-left: 40px;
    display:grid;
    grid-template-columns: repeat(3,1fr);
    
}
.merlin-grids2{
    padding-right:40px;
    grid-gap:10px;
}

.merlin-grids1{
    padding-top: 10px;
    padding-right:30px;
    grid-gap:20px;
}
.merlin-grids2 img:nth-child(2){
    padding-top: 70px;
}

@media screen and (max-width:452px){
    .merlin-grids2 img:nth-child(2){
        padding-top: 20px;
    }
}
@media screen and (max-width:780px) and (min-width:1014px){
    .merlin-grids1{
        padding-top: 160px;
    }
}
@media screen and (max-width:1280px){
    .merlin-grids1, .merlin-grids2{
        padding-left: 30px;
        padding-right: 30px;
    }
}

.images1{
    width:800px;
    padding: 10px;
   
}
.images2{
    width:800px;
    padding: 10px;
   
}


figure{
    display: inline-block;
    padding-left: 50px;
}

.c1{
    position: relative;
}
.c1 .btn{


    position: absolute;
    top: 50%;
    left: 40%;
    font-size:xxx-large;
    color:black;
    background-color:white ;
    font-family: 'Quicksand', sans-serif;

  
}
.c2{
    position: relative;
}
.c2 .btn{


    position: absolute;
    top: 50%;
    left: 50%;
    font-size: xxx-large;
    color:rgb(0, 0, 0);
    background-color: #fff;
    font-family: 'Quicksand', sans-serif;
}
 